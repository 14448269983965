import { graphql } from 'react-apollo'
import { assessment } from '../../graphql/query/assessment'
import Measure from './measure'
import { ProgressCircle } from '../../common/progressCircle'
import { Toolbar } from '../../common/Toolbar'
import StateButton from '../../common/StateButton'
import Header from '../../common/Header'
import { formatDate } from '../../../helpers/time'
import { scoreInfo, setPosition, heartLegend, startLegend } from '../../../helpers/assessment'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { style } from '../style'

@graphql(assessment)
export default class SummaryPage extends React.Component {
  state = { description: false }
  render() {
    const {
      assessment_type, assessment_type: { name, description, _assessment_types },
      msrs, obs, created_at, result
    } = this.props.data.assessment
    const score = result && result.score || 0
    const assessScore = Math.round(score)
    const { actions=[] } = this.props
    const assessInfo = scoreInfo(score, assessment_type)
    const sortMsrs = sortBy(setPosition(msrs), 'position')
    const outcome = _assessment_types.name === 'Outcome_Measures'
    const sortedMsrs = outcome ? msrs : filter(sortMsrs, 'weight')
    const RenderArrow = this.state.description ? KeyboardArrowDown : KeyboardArrowUp
    return (
      <ScrollView style={style.container}>
        <Header
          headerTitle={(
            <TouchableOpacity onPress={this.toggleBlurb}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 16, fontWeight: '600'}}>{`${name} Summary`}</Text>
                <RenderArrow style={{ fontSize: 16, marginTop: 2 }} htmlColor="black" />
              </View>
              <Text style={{ paddingTop: 3, fontSize: 14, color: 'gray'}}>{formatDate(created_at)}</Text>
            </TouchableOpacity>
          )}
          headerRight={(
            <View style={{flexDirection: 'row'}}>
              <ProgressCircle
                value={assessScore}
                label={assessScore}
                size={50}
                thickness={8}
                color={assessInfo.color}
                fontSize={20}
              />
            </View>
          )}
        />
        {this.state.description &&
          <React.Fragment>
            <Text style={style.padding}>
              {description}
            </Text>
            {this.renderLegend(assessment_type)}
          </React.Fragment>
        }
        {!outcome && <Toolbar buttons={[this.summaryActions()]} />}
        <View>
          <Text style={style.measureTitle}>Measures</Text>
          {sortedMsrs.map((msr, i, arr) =>
            <Measure
              key={i}
              last={arr.length - 1 === i}
              msr={msr}
              obs={obs}
              assessment_type={assessment_type} />)}
        </View>
      </ScrollView>
    )
  }

  renderLegend = assessment_type => {
    const legend = assessment_type.risk ? heartLegend : startLegend
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', paddingVertical: 25, flexWrap: 'wrap' }}>
        {legend.map(leg => {
          return (
            <View key={uniqueId()} style={{ flexDirection: 'row', alignItems: 'center', width: 120 }}>
              <View style={{ width: 60, height: 25, borderRadius: 3, backgroundColor: leg.color, border: '3px double #FFFEEB'}} />
              <Text style={{ color: '#2d2d2d', fontSize: 14, fontWeight: '500', paddingLeft: 10 }}>{leg.label}</Text>
            </View>
          )
        })}
      </View>
    )
  }

  summaryActions = () => {
    const { actions=[] } = this.props
    return (
      <View style={{ flexDirection: 'row' }}>
        {actions.map((action, i) =>
          <View style={style.actionContainer} key={i}>
            {action}
          </View>
        )}
      </View>
    )
  }

  toggleBlurb = () => this.setState({ description: !this.state.description })
}
