import { SubmitButton } from './submit'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import Collapse from '@material-ui/core/Collapse'
import Measure from './measures/measure'

export class MeasureView extends Component {
  state = { description: false }
  render () {
    const { title, id, ...rest } = this.props
    const { description } = this.state

    return (
      <View style={this.props.last ? styles.measure : [styles.measure, styles.msrBorder]}>
        <Text style={styles.msrNumber}>{`${this.props.position}.`}</Text>
        <View style={styles.msrContent}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={styles.measureTitle}>{this.renderTitle(this.props.msr)}</Text>
            {this.renderDescriptionToggle()}
          </View>
          {this.renderDescription()}
          <Measure {...rest} />
        </View>
      </View>
    )
  }

  renderDescriptionToggle = () => {
  const Icon = this.state.description ? KeyboardArrowDown : KeyboardArrowUp
   return <TouchableOpacity onPress={this.toggleDescription}>
      <Icon
        htmlColor="black"
        fontSize="small"
      />
    </TouchableOpacity>
  }

  renderDescription = () =>
    <Collapse in={this.state.description} unmountOnExit>
      {this.state.description &&
        <View style={{paddingVertical: 15}}>
          <Text style={styles.description}>{this.props.msr.description}</Text>
        </View>
      }
    </Collapse>

  toggleDescription = () => this.setState({ description: !this.state.description })

  renderTitle = ({optional, name}) => (
    `${name} ${optional ? "(optional)" : ""}`
  )
}

export class PanelView extends Component {
  state = {index: 0}

  render() {
    const { index } = this.state
    const { assessment } = this.props
    const disabled = index == this.props.children.length - 1
    return (
      <ScrollView>
        {this.props.children[index]}
        <View style={styles.panelView}>
          {this.nextButton(index, disabled, assessment )}
          {this.previousButton(index)}
        </View>
      </ScrollView>
    )
  }

  nextButton = (index, lastMsr, assessment) => (
    <View>
      {lastMsr
       // submit has to pass onSubmit to go to summary page
       ? <SubmitButton assessment={assessment} />
       : <TouchableOpacity onPress={() => this.setState({index: this.state.index + 1})}>
         <ChevronRight />
       </TouchableOpacity>
      }
    </View>
  )

  previousButton = (index) => (
    <TouchableOpacity
      disabled={index == 0}
      onPress={() => this.setState({index: this.state.index - 1})}
    >
      <ChevronLeft color={index == 0 ? 'gainsboro' : 'black'} />
    </TouchableOpacity>
  )
}

const styles = {
  panelView: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  measure: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    flexDirection: 'row',
    flex: 1
  },
  msrBorder: {
    borderBottomWidth: 0.5,
    borderBottomColor: 'gainsboro',
  },
  msrContent: {
    marginLeft: 15,
    marginTop: 3,
    flex: 1
  },
  msrNumber: {
    fontSize: 18,
    fontWeight: '600',
    paddingTop: 3
  },
  measureTitle: {
    fontWeight: '500',
    fontSize: 16,
  },
  hidden: {
    display: 'none'
  },
  description: {
    color: 'gray',
    fontSize: 11
  }
}
