import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native-web'
import Measure from './measure'
import { msrGuide } from './inputs'
import { find } from 'lodash'
import Menu from '@material-ui/icons/Menu'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Collapse from '@material-ui/core/Collapse'

export class MultiMeasure extends React.Component {
  state = { selectedMeasure: null, guide: false }

  componentWillMount() {
    this.props.msr.msrs.map(({id}) => {
      if (this.props.assessment.obs[id]) {
        this.setState({selectedMeasure: id})
      }
    })
  }

  render() {
    const { msrs } = this.props.msr
    const { selectedMeasure, guide } = this.state
    const selected = this.selected(selectedMeasure)

    return (
      !selected ?
      <View style={[styles.display, styles.multiMeasureView]}>
        {msrs.map(({id, name}) => {
           let msrSelected;
           if (this.props.assessment.obs[id]) {
             msrSelected = this.selected(id)
           }
           return (
             <View key={id} style={styles.multiMeasureButton} >
               <TouchableOpacity
                 style={{ paddingHorizontal: 15, paddingVertical: 8, backgroundColor: msrSelected ? 'grey' : 'rgb(0, 122, 255)', borderRadius: 3 }}
                 onPress={() => this.setState({selectedMeasure: id})}
               >
                <Text style={{ color: 'white', fontWeight: '500' }}>{name}</Text>
              </TouchableOpacity>
             </View>
           )
        })}
      </View> :
      <View>
        <TouchableOpacity style={styles.menuIcon} onPress={() => this.setState({ selectedMeasure: null })}>
          <Menu htmlColor="rgb(0,0,0)" fontSize="small" />
        </TouchableOpacity>
        <View style={styles.msrGuideContainer}>
          <Text>{selected.name}</Text>
          {selected.protocol.length > 0 &&
            <TouchableOpacity
              onPress={this.guideToggle}
            >
              <ErrorOutline
                htmlColor={this.state.guide ? '#3B99FC' : 'rgba(0,0,0,0.4)'}
                fontSize="small"
              />
            </TouchableOpacity>
          }
        </View>
        {selected.protocol &&
          <Collapse in={this.state.guide} unmountOnExit>
            {msrGuide(selected.protocol, this.state.guide)}
          </Collapse>
        }
        <Measure {...this.props} msr={selected} />
      </View>
    )
  }

  guideToggle = () => {
    this.setState({ guide: !this.state.guide })
  }

  selected = id => find(this.props.msr.msrs, {id})
}


const styles = {
  display: {
    display: 'flex',
    flexDirection: 'row'
  },
  menuIcon: {
    position: 'absolute',
    right: 0,
    top: -35
  },
  msrGuideContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  multiMeasureView: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  multiMeasureButton: {
    marginRight: 10,
    marginTop: 10,
    width: 250,
  },
  selected: {
    backgroundColor: 'black'
  }
}
