import { graphql } from '../../helpers/graphql'
import { assessment_types, client_assessments } from '../graphql/query/assessment'
import { create_assessment } from '../graphql/mutation/assessment'
import { newAssessment as style } from './style'

export default props => {
  return (
    <View style={{paddingVertical: 10, width: 400}}>
      <AssessmentList {...props} />
    </View>
  );
}

const sortAssess = assessments => {
  return orderBy(assessments, [assess => assess.name.toLowerCase()], ['asc'])
}

@graphql( assessment_types , { options: {fetchPolicy: "network-only"} })
@graphql(create_assessment, {
  props: ({ownProps: {client: {uuid, birth_date, gender}, provider}, mutate}) => ({
    createAssessment: assessment_type =>
      mutate({variables: {client: uuid, provider: provider.uuid, assessment_type, birth_date, gender},
              update: addAssessment(uuid)})})})
class AssessmentList extends Component {
  state = {loading: false}

  render() {
    const {assessment_types} = this.props.data
    const [others, start] = partition(assessment_types, (assess) =>
      assess._assessment_types.name === 'HEART' || assess._assessment_types.name === 'Outcome_Measures')
    const [heart, outcome] = partition(others, (assess) => assess._assessment_types.name === 'HEART')
    const orderedOutcomes = sortAssess(outcome)
    return (
      this.state.loading
        ? <ActivityIndicator style={{marginTop: 20}} color="gray" size="large" />
        : <View style={{ marginTop: -15 }}>
            <Text style={style.assessTitle}>HEART</Text>
            {heart.map(assess => this.renderType(assess))}
            <Text style={style.assessTitle}>START</Text>
            {start.map(assess => this.renderType(assess))}
            {this.props.provider.email === 'bwatson@optimisdev.com' &&
              <View>
                <Text style={style.assessTitle}>OUTCOME MEASURES</Text>
                {orderedOutcomes.map(assess => this.renderType(assess))}
              </View>
            }
        </View>
    )
  }

  renderType = assess =>
    <View key={uniqueId()} style={style.subTypes}>
      <Text style={style.subTitle}
            onPress={() => {this.createAssessment(assess.id)}}>
        {assess.name}
      </Text>
    </View>

  createAssessment = (id) => {
    this.setState({loading: true});
    this.props.createAssessment(id)
        .then(res => {
          this.props.closeModal()})
  }
}

const addAssessment = client => (store, {data: {create_assessment: assessment}}) => {
  const data = store.readQuery({
    query: client_assessments,
    variables: {uuid: client}
  })
  store.writeQuery({
    query: client_assessments,
    variables: {uuid: client},
    data: {...data, client_assessments: [assessment, ...data.client_assessments]}
  })
}
