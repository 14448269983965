import { useEffect } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import JssProvider from 'react-jss/lib/JssProvider'
import { MuiThemeProvider as MuiNextTheme } from '@material-ui/core/styles'
import { createGenerateClassName } from '@material-ui/styles'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import useReactRouter from 'use-react-router'
import { Provider } from 'react-redux'
import { ApolloProvider as ApolloClientProvider } from '@apollo/client'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { BrowserRouter, Route } from 'react-router-dom'

import ocpTheme from './themes/ocp'
import { theme } from './themes/ocpNext'
import createStore from './config/store'
import { clientPromise } from './helpers/client'
import App from './components/App'

class LocalizedUtils extends MomentUtils {
  getWeekDays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

export class Root extends Component {

  state = {client: null}
  async componentDidMount() {
    const client = await clientPromise
    this.setState({client})
  }

  render() {
    const {client} = this.state

    return client && (
      <JssProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
              <ApolloClientProvider client={client}>
                <Provider store={createStore(client)}>
                  <BrowserRouter>
                    <MuiPickersUtilsProvider utils={LocalizedUtils}>
                      <ScrollToTop {...this.props}>
                          <Route path="/" component={App} />
                      </ScrollToTop>
                    </MuiPickersUtilsProvider>
                  </BrowserRouter>
                </Provider>
              </ApolloClientProvider>
            </ApolloHooksProvider>
          </ApolloProvider>
        </MuiThemeProvider>
      </JssProvider>
    )
  }
}

const generateClassName = createGenerateClassName({dangerouslyUseGlobalCSS: true})

const ScrollToTop = (props) => {
  const { history } = useReactRouter();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  });

  return props.children;
};
