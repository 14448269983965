import Assessment from '@material-ui/icons/Assessment'
import Assignment from '@material-ui/icons/Assignment'
import FitnessCenter from '@material-ui/icons/FitnessCenter'
import { actionsStyle as style } from './style'

class Actions extends Component {
  render() {
    const {history, url, assess: {result, workoutId}} = this.props
    const score = result && result.score
    const actionBtns = [
      {Icon: Assignment, onPress: () => history.push(`${url}/form`),
       disabled: false, color: '#ED7132' },
      {Icon: Assessment, onPress: () => history.push(`${url}/summary`),
       disabled: score === null, color: score !== null ? '#F7E465' : 'gainsboro'},
      {Icon: FitnessCenter, onPress: () => history.push(`${url}/workout`),
       disabled: !workoutId, color: workoutId ? '#70CDCB' : 'gainsboro'}
    ]

    return (
      <View style={{flexDirection: 'row'}}>
        {actionBtns.map(this.renderAction)}
      </View>
    )
  }

  renderAction = ({Icon, onPress, disabled, color}, index) =>
    <TouchableOpacity
      key={index} onPress={onPress}
      style={style.iconBtn} disabled={disabled}
    >
      <Icon htmlColor={color} size={30} />
    </TouchableOpacity>
}

export default Actions
