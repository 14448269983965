import { graphql } from '../../helpers/graphql'
import { score } from '../graphql/mutation/assessment'
import StateButton from '../common/StateButton'
import { every, reject} from 'lodash'

@graphql(score, { props: ({ mutate }) => ({
  score: (id) =>
    mutate({ variables: { id }})
  })
})
export class SubmitButton extends Component {
  render() {
    const {score, onSubmit: onCompleted, assessment, posting} = this.props

    return (
      <View style={{ flexDirection: 'row-reverse', marginVertical: 15 }}>
        <StateButton
          onSubmit={() => score(assessment.id)}
          onCompleted={onCompleted}
          disabled={posting || !isComplete(assessment)}
          text={
            posting
              ? "Updating"
              : assessment.score
                ? "Resubmit"
                : "Submit"
          }
          style={{ width: 200 }}
        />
      </View>
    )
  }
}

const isComplete = ({msrs, obs}) => (
  every(reject(msrs, 'optional'), msr => (
    msr.display === 'Multi'
    ? some(msr.msrs, ({id}) => obs[id])
    : obs[msr.id]
  ))
)
