import { graphql } from '../helpers/graphql'
import { useState, useContext } from 'react'
import { Mutation } from 'react-apollo'
import { assessment } from './graphql/query/assessment'
import Summary from './assessments/summary'
import { buildWorkout, changeWorkoutDate } from './graphql/mutation/workout'
import { toggleModal } from '../config/actions/modal'
import moment from 'moment'
import { toggleSnackbar } from '../config/actions/snackbar'
import StateButton from './common/StateButton'
import { Button } from './common/Button'
import { DatePicker } from './common/DatePicker'
import style from './style'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalModalContext } from './common/GlobalModal'

const graphqlBuildWorkout =
  graphql(buildWorkout, {
    props: ({ownProps: {id}, mutate}) => {
      const variables = {id}
      return {
        buildWorkout: () => mutate({
          variables,
          update: (store, {data: { build_workout }}) => {
            const data = store.readQuery({query: assessment, variables})
            data.assessment.workoutId = build_workout
            store.writeQuery({query: assessment, variables, data})
          }
        })
      }}
  })

@graphql(assessment, {
  options: props => ({
    variables: { id: props.id },
    fetchPolicy: "network-only"
  })
})
@graphqlBuildWorkout
export default class extends Component {
  render() {
    const { id, data } = this.props
    return (
      <Summary
        id={id}
        actions={this.actions(data.assessment)} />
    )
  }

  actions = ({workoutId, workout}) => {
    const {buildWorkout, onBuildWorkout, data} = this.props
    return [
      workout && <ChangeWorkoutDateButton workoutId={workoutId} startDate={workout.start_date} />,
      <BuildWorkoutButton
        active={data.assessment.client.active}
        workoutId={workoutId}
        buildWorkout={buildWorkout}
        onBuildWorkout={onBuildWorkout}
      />,
    ]
  }
}

const ChangeWorkoutDateButton = props => {
  const [startDate, setDate] = useState(props.startDate)
  const { openModal, closeModal } = useContext(GlobalModalContext)
  const dispatch = useDispatch()

  return (
    <Mutation mutation={changeWorkoutDate}>
      {(changeWorkoutDate, { loading }) => {
        return (
          <TouchableOpacity onPress={() => (
            openModal(
              <View>
                <Text style={[style.modalTitle, { paddingVertical: 5, fontSize: 18 }]}>Change workout start date</Text>
                <Text style={{ paddingVertical: 5, fontSize: 14, color: 'gray' }}>Current start date: {moment(startDate).format("MM-DD-YYYY")}</Text>
                <View style={{ paddingVertical: 15 }}>
                  <DatePicker
                    onDateChange={
                      date => {
                        changeWorkoutDate({
                          variables: {
                            id: props.workoutId,
                            date: moment(date).format("YYYY-MM-DD")
                          }
                        }).then(() => {
                          closeModal()
                          setDate(moment(date).format("MM-DD-YYYY"))
                          dispatch(toggleSnackbar('Workout date changed!'))
                        }).catch(() => {
                          closeModal()
                          dispatch(toggleSnackbar('Something went wrong. Please try again.'))
                        });
                      }
                    }
                    format='MM-DD-YYYY'
                    placeHolder="new date"
                    showIcon={false}
                  />
              </View>
              </View>
            )
          )}>
            <StateButton
              text="Change Date"
              loading={loading}
            />
          </TouchableOpacity>
        )
      }}
    </Mutation>
  )
}

const BuildWorkoutButton = props => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { openModal, closeModal } = useContext(GlobalModalContext)
  const { workoutId } = props

  const OnConfirm = () => {
    const {buildWorkout, onBuildWorkout} = props
    setLoading(true)
    buildWorkout()
    .then((data) => {
      setLoading(false)
      onBuildWorkout(data.data.build_workout.workoutId)
    })
    .catch(() => {
      setLoading(false)
      dispatch(toggleSnackbar('Unable to build workout'))
    })
  }
  return (
    <TouchableOpacity
      onPress={() => (
        workoutId &&
          openModal(
            <ConfirmModal
              toggleModal={closeModal}
              onConfirm={OnConfirm}
            />
          )
      )}
    >
      <StateButton
        text={`${workoutId ? 'Rebuild' : 'Build'} Workout`}
        loading={loading}
        onSubmit={OnConfirm}
      />
    </TouchableOpacity>
  )
}

const ConfirmModal = ({toggleModal, onConfirm}) =>
  <View style={{paddingVertical: 5}}>
    <Text style={{ fontSize: 16, paddingBottom: 10 }}>Are you sure you want to rebuild this workout?</Text>
    <Text style={{ fontSize: 12, paddingBottom: 20 }}>
      If you choose to rebuild this workout, all previous information
      (compliance, notes, etc.) will be deleted.
      You will not get this information back once this workout has been rebuilt.
    </Text>
    <View style={{ width: 200, alignSelf: 'flex-end' }}>
      <Button
        text="Confirm"
        onPress={() => {toggleModal(); onConfirm();}}/>
    </View>
  </View>
