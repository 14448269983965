import React from 'react';
import { graphql } from 'react-apollo'
import { ApolloConsumer } from '@apollo/client';
import { Route, Redirect, Switch } from 'react-router-dom'
import { trimStart, trimEnd } from 'lodash'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { currentProvider } from './graphql/query/client'
import OcpBar from './home/OcpBar'
import Snackbar from './common/Snackbar'
import { provider } from './graphql/query/client'
import Login from './home/login'
import Signup from './home/signup'
import { GlobalModalProvider } from './common/GlobalModal';
import ClientContainer from './profile'
import ClientsTable from './clients'
import User from './user'
import Messages from './messages'
import Delta from './delta'
import DeltaEvent from './delta/event'
import Calendar from './calendar'
import Tutorial from './tutorial'
import { WorkoutProvider } from './workout-editor/WorkoutContext';

@graphql(currentProvider)
export default class App extends Component {

  componentDidMount() {
    this.props.history.replace(this.getRedirect())
  }

  render() {
    const { data } = this.props
    return (
      <ApolloConsumer>
        {client => (
          <View style={{ height }}>
            <WorkoutProvider>
              <GlobalModalProvider>
                <OcpBar
                  {...this.props}
                  provider={data && data.currentProvider}
                  logout={() => this.logout(client)}
                  logo={require("../../images/logo.png")}
                  user={data && data.currentProvider}
                  clientsRoute={() => this.props.history.push('/')}
                  calendarRoute={() => this.props.history.push('/calendar')}
                  msgsRoute={() => this.props.history.push('/messages')}
                  profileRoute={() => this.props.history.push('/profile')}
                  tutorialRoute={() => this.props.history.push('/tutorial')}
                />
                <Switch>
                  <PrivateRoute
                    {...this.props}
                    path="/profile"
                    component={ProfileContainer}
                  />
                  <PrivateRoute
                    {...this.props}
                    exact
                    path="/"
                    component={ClientsContainer}
                  />
                  <PrivateRoute
                    {...this.props}
                    path="/delta"
                    component={DeltaContainer}
                  />
                  <PrivateRoute
                    {...this.props}
                    path="/event"
                    component={DeltaEventContainer}
                  />
                  <PrivateRoute
                    {...this.props}
                    path="/messages"
                    component={MessagesContainer}
                  />
                  <PrivateRoute
                    data={data}
                    {...this.props}
                    path="/calendar"
                    component={CalendarContainer}
                  />
                  <PrivateRoute
                    {...this.props}
                    path="/client/:id"
                    component={ClientContainer}
                  />
                  <PrivateRoute
                   {...this.props}
                   path="/tutorial"
                   component={TutorialContainer}
                 />
                  <Route
                    path="/login"
                    component={LoginContainer}
                  />
                  <Route
                    path="/signup"
                    component={SignupContainer}
                  />
                </Switch>
                <Snackbar />
              </GlobalModalProvider>
            </WorkoutProvider>
          </View>
        )}
      </ApolloConsumer>
    )
  }

  logout = async (client) => {
    client.cache.reset()
    await AsyncStorage.clear()
    this.props.history.push('/login')
  }

  getRedirect = () => {
    const { pathname, search } = this.props.location
    return trimStart(search, '?r=') || trimEnd(pathname, '/')
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
 return (
    <Route
      {...rest}
      render={props =>
        rest.data.currentProvider
          ? <Component {...props} />
          : <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
      }
    />
  )
}

@graphql(currentProvider)
class ProfileContainer extends Component {
  render() {
    const { data: { currentProvider } } = this.props
    return (
      <View style={{ height, flex: 1 }}>
        <User user={currentProvider} />
      </View>
    )
  }
}
class CalendarContainer extends Component {
  render() {
    return (
      <View style={{ marginTop: 64, height }}>
        <Calendar />
      </View>
    )
  }
}

class DeltaContainer extends Component {
  render() {
    return (
      <View style={{ marginTop: 64, padding: 15 }}>
        <Delta {...this.props} />
      </View>
    )
  }
}

class TutorialContainer extends Component {
  render() {
    return (
      <View style={{ marginTop: 64, padding: 15 }}>
        <Tutorial {...this.props} />
      </View>
    )
  }
}

class DeltaEventContainer extends Component {
  render() {
    return (
      <View style={{ marginTop: 64, padding: 15 }}>
        <DeltaEvent {...this.props} />
      </View>
    )
  }
}

class MessagesContainer extends Component {
  render() {
    return (
      <View style={{ marginTop: 64, padding: 15 }}>
        <Messages {...this.props} />
      </View>
    )
  }
}

@graphql(currentProvider)
class ClientsContainer extends Component {
  render() {
    return (
      <View style={{ marginTop: 64, padding: 15 }}>
        <ClientsTable {...this.props} />
      </View>
    )
  }
}

class LoginContainer extends Component {
  render() {
    return (
      <View style={{height, flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <View style={{width: 350}}>
          <Login onSignin={() => this.props.history.push('/')}/>
        </View>
      </View>
    )
  }
}

class SignupContainer extends Component {
  render() {
    return (
      <View style={{height, flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <View style={{width: 350}}>
          <Signup onSignup={() => this.props.history.push('/')}/>
        </View>
      </View>
    )
  }
}

const { height } = Dimensions.get('window')
