import styles from '../style'
import { RadioButtonGroup } from '../../../common/Radio'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Collapse from '@material-ui/core/Collapse'

export class TextInputMeasure extends Component {
  state = {valid: true, value: null, guide: false}
  componentWillMount() {
    this.timer = null
  }
  render() {
    const { min, max, unit = '', name, protocol, type } = this.props.msr
    const proto = protocol.length > 0 ? protocol : this.props.protocol
    const { valid, guide } = this.state
    const value = this.state.value == null
      ? this.props.freetext || this.props.value
      : this.state.value
    return (
      <View style={styles.inputSpacing}>
        <View style={styles.msrTitleContainer}>
          <Text style={styles.msrTitle}>{this.props.label}</Text>
          {proto.length > 0 &&
            <TouchableOpacity
              onPress={this.guideToggle}
            >
              <ErrorOutline
                htmlColor={guide ? '#3B99FC' : 'rgba(0,0,0,0.4)'}
                fontSize="small"
              />
            </TouchableOpacity>
          }
        </View>
        <Collapse in={guide} unmountOnExit>
          {msrGuide(proto, guide)}
        </Collapse>
        <TextInput
          style={Platform.OS == 'android' ? styles.androidInput : [styles.textInput, {outline: 'none'}]}
          value={value !== null && value !== undefined ? value.toString() : ''}
          onChange={this.onChange}
        />
        {type === "FITB" || type === "Q&A"
          ? null
          : <Text style={[valid ? styles.webValidInput : styles.webErrorInput, styles.inputHint]}>
              {min} - {max} {unit && unit.plural}
            </Text>
        }
      </View>
    )
  }

  guideToggle = () => {
    this.setState({ guide: !this.state.guide})
  }

  delayPost = (value, type) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.state.valid && this.props.postOb(value, null, type)
    }, 1500);
  }

  onChange = ({target: {value}}) => {
    const { type } = this.props.msr
    this.setState({value}, () => {
      if (type === "FITB") {
        return this.delayPost(value)
      } else if (type === "Q&A") {
        return this.delayPost(value, "text")
      } else {
        return this.validate(value) && this.delayPost(value)
      }
    })
  }

  validate = value => {
    const { min, max } = this.props.msr
    let valid
    if (value === '' || value == undefined) {
      valid = true
      return valid
    }
    valid = Number(min) <= Number(value) && Number(value) <= Number(max)
    this.setState({valid})
    return valid
  }
}

export class RadioMeasure extends Component {
  state = { guide: false }
  render() {
    const {description, name, optionables, protocol} = this.props.msr
    const { guide } = this.state
    const proto = protocol.length > 0 ? protocol : this.props.protocol
    return (
      <View style={styles.msrSpacing}>
        <RadioButtonGroup
          label={description || name}
          msrGuide={msrGuide(proto, guide)}
          toggleState={guide}
          msrToggle={proto.length > 0 &&
            <TouchableOpacity
            onPress={this.guideToggle}
            >
              <ErrorOutline
                htmlColor={guide ? '#3B99FC' : 'rgba(0,0,0,0.4)'}
                fontSize="small"
              />
            </TouchableOpacity>
          }
          options={
            optionables
              .map(({value, legend, option: { text: label }}) => ({ value, label, legend }))
          }
          value={this.props.value}
          action={this.props.postOb}
        />
      </View>
    )
  }
  guideToggle = () => this.setState({ guide: !this.state.guide })
}

export const HybridMeasure = props => {

  return (
    <View>
      <TextInputMeasure {...props} />
      <RadioMeasure {...props} />
    </View>
  )
}

export const msrGuide = (protocol, collapse) => {
  if (collapse && protocol) {
    return protocol.map((guide, index) => (
      <View style={styles.guideContainer} key={index}>
        <Text style={styles.guideText}>{index + 1}.</Text>
        <Text style={[styles.guideText, {paddingLeft: 5}]}>{guide.text}</Text>
      </View>
    ))
  }
}

export const HiddenMeasure = () =>
  <Text style={{display: 'none'}}></Text>
